import React from 'react';

export default function Home() {
    return (
        <main>
            <header className="intro">
                <h1>Hi, I'm Trey. A junior developer</h1>
            </header>
        </main>
    );
};